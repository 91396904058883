@import "@/styles/variables.scss";

.avatar {
  border-radius: 50%;
  object-fit: cover;

  &__isSquare {
    border-radius: $border-radius-base;
  }
}
